var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();}}},[_c('div',{staticClass:"d-flex flex-row flex-wrap"},[_c('div',{staticClass:"col-12 px-0"},[_c('filter-master',{attrs:{"m-headquarter-id":_vm.editData && _vm.editData.mHeadquarterId
            ? _vm.editData.mHeadquarterId
            : !_vm.authUser.canAccessAllHeadQuarters
            ? _vm.authUser.mHeadquarterId
            : null,"m-division-id":_vm.editData && _vm.editData.mDivisionId
            ? _vm.editData.mDivisionId
            : !_vm.authUser.canAccessAllDivisions
            ? _vm.authUser.mDivisionId
            : null,"m-station-id":_vm.editData && _vm.editData.mStationId
            ? _vm.editData.mStationId
            : !_vm.authUser.canAccessAllStations
            ? _vm.authUser.mStationId
            : null,"disabled-input":_vm.disabledInput,"label-headquarter":"กองบัญชาการ","label-division":"กองบังคับการ","label-station":"กองกำกับการ","default-label":"-","hidden-input":{ school: true, schoolClass: true },"invalid":_vm.invalid,"validation":_vm.validation},model:{value:(_vm.filterMaster),callback:function ($$v) {_vm.filterMaster=$$v},expression:"filterMaster"}})],1),_c('div',{staticClass:"col-12 px-0"},[_c('filter-province',{attrs:{"m-province-id":_vm.filterProvince.mProvinceId,"m-amphur-id":_vm.filterProvince.mAmphurId,"disabled-input":_vm.disabledInput,"hidden-input":{ school: true },"default-label":"-","invalid":_vm.invalid,"validation":_vm.validation},model:{value:(_vm.filterProvince),callback:function ($$v) {_vm.filterProvince=$$v},expression:"filterProvince"}})],1),_c('div',{staticClass:"col-12 px-2 mb-2"},[_c('label',{staticClass:"font-weight-bold",attrs:{"for":"lastName"}},[_vm._v("ชื่อโรงเรียน")]),_c('b-form-input',{attrs:{"id":"lastName","state":_vm.invalid ? _vm.validation.form.m_school_name.required : null,"type":"text","disabled":_vm.disabled},model:{value:(_vm.form.m_school_name),callback:function ($$v) {_vm.$set(_vm.form, "m_school_name", $$v)},expression:"form.m_school_name"}}),_c('b-form-invalid-feedback',{attrs:{"id":"lastName-feedback"}},[_vm._v(" กรุณากรอกชื่อโรงเรียน ")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }